import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty, isNull } from "lodash";
import Slider from "react-slick";
import {
  Grid,
  Box,
  Button,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
  Autocomplete,
  TextField,
  Rating,
} from "@mui/material";
import {
  ArrowForward,
  ArrowDownward,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  ContentCopy,
} from "@mui/icons-material";
import "./styles.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import Images from "../../../Config/Images";
import { Setting } from "../../../Utils/Setting";
import CDropDown from "../../../Components/CDropDown";
import { getApiData } from "../../../Utils/APIHelper";
import authActions from "../../../Redux/reducers/auth/actions";
import {
  askForPermissionToReceiveNotifications,
  onMessageListener,
} from "../../../push-notification";

const { setDropDownData, setReview } = authActions;

const errorObj = {
  sectorErr: false,
  jobRoleErr: false,
  newJobRoleErr: false,
  sectorMsg: "",
  jobRoleMsg: "",
  newJobRoleMsg: "",
};

export default function Home() {
  const { dropdowndata } = useSelector((state) => state.auth);
  const [sector, setSector] = useState({});
  const [jobRole, setJobRole] = useState({});
  const [jobRoleArr, setJobRoleArr] = useState([]);

  const [candidateReview, setCandidateReview] = useState([]);
  const [employerReview, setEmployerReview] = useState([]);
  const [errObj, setErrObj] = useState(errorObj);

  const [newJob, setNewJob] = useState("");
  const [viewNewJob, setViewNewJob] = useState(false);

  const isCandidateEmpty = isEmpty(candidateReview);
  const isEmployerEmpty = isEmpty(employerReview);

  const isOne = employerReview.length === 1;

  const navigate = useNavigate();
  const sliderRef = useRef();
  const firstSliderRef = useRef();
  const firstSliderImgRef = useRef();

  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_HOME;
    getDropDownData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    askForPermissionToReceiveNotifications();
    onMessageListener();
  }, []);

  function err(message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      progress: undefined,
    });
  }

  function jobRoleData(value) {
    dropdowndata?.sectors.map((ii) => {
      if (ii?.value === value) {
        setJobRoleArr(ii?.job_roles);
      }
    });
    setJobRole({ value: "", label: "" });
  }

  async function getDropDownData() {
    try {
      let endPoint = `${Setting.endpoints.dropdown}`;
      const response = await getApiData(endPoint, "GET", null);
      if (response && response.status && response.status === true) {
        dispatch(setDropDownData(response?.data));
        getReviewData();
      } else {
        console.log("err==>>");
        err(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  async function getReviewData() {
    try {
      let endPoint = `${Setting.endpoints.userReviewList}`;
      const response = await getApiData(endPoint, "GET", null);
      if (response && response.status && response.status === true) {
        dispatch(setReview(response?.data));
        setCandidateReview(response?.data?.user_review);
        setEmployerReview(response?.data?.employer_review);
      } else {
        console.log("err==>>");
        err(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(sector)) {
      valid = false;
      error.sectorErr = true;
      error.sectorMsg = "Please select sector";
    } else if (isEmpty(jobRole?.label) && !viewNewJob) {
      valid = false;
      error.newJobRoleErr = false;
      error.jobRoleErr = true;
      error.jobRoleMsg = "Please select job role";
    } else if (isEmpty(newJob) && viewNewJob) {
      valid = false;
      error.jobRoleErr = false;
      error.newJobRoleErr = true;
      error.newJobRoleMsg = "Please enter new job role";
    }

    setErrObj(error);
    if (valid) {
      navigate("/candidate/signup", {
        state: {
          sector: sector,
          jobRole: viewNewJob ? { label: newJob } : jobRole,
        },
      });
    }
  }

  const setting = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: isOne ? 1 : 2,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    swipe: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          dots: false,
          arrows: false,
          swipe: false,
        },
      },
    ],
  };

  const firstSetting = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 6000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    arrows: false,
  };

  function renderFirst() {
    const isSector = isEmpty(sector) || viewNewJob;
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={6}
            direction="column"
            justifyContent="center"
          >
            <Typography className="fontsize primarycolor">
              Find your next job, fast.
            </Typography>
            <Typography className="primarycolor" style={{ marginBottom: 16 }}>
              Set your job preferences and get connected with employers
            </Typography>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8 }}
            >
              Select the sector
            </Typography>
            <Autocomplete
              style={{ maxWidth: sm ? "unset" : 300 }}
              options={dropdowndata?.sectors}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select sector"
                  error={errObj.sectorErr}
                  helperText={errObj.sectorErr ? errObj.sectorMsg : null}
                />
              )}
              onChange={(e, v) => {
                setSector({ value: v.value, label: v.label });
                jobRoleData(v.value);
                setErrObj({ ...errObj, sectorErr: false, sectorMsg: "" });
              }}
              onInputChange={(v) => {
                if (!isNull(v) && v.target.value.length === 0) {
                  setSector({});
                }
              }}
              freeSolo
              size="small"
              value={sector?.label}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Select the job role
            </Typography>
            <Autocomplete
              key={jobRole?.label}
              style={{ maxWidth: sm ? "unset" : 300 }}
              options={jobRoleArr}
              disabled={isSector}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select job"
                  error={errObj.jobRoleErr}
                  helperText={errObj.jobRoleErr ? errObj.jobRoleMsg : null}
                />
              )}
              onChange={(e, v) => {
                setJobRole({ value: v.value, label: v.label });
                setErrObj({ ...errObj, jobRoleErr: false, jobRoleMsg: "" });
              }}
              onInputChange={(v) => {
                if (!isNull(v) && v.target.value.length === 0) {
                  setJobRole({});
                }
              }}
              freeSolo
              size="small"
              value={jobRole?.label}
            />
            <Typography
              className="primarycolor"
              style={{
                marginTop: 8,
                cursor: "pointer",
                // textDecoration: "underline",
              }}
              onClick={() => {
                if (!isEmpty(sector)) {
                  setViewNewJob(!viewNewJob);
                  setJobRole({ value: "", label: "" });
                  setNewJob("");
                  setErrObj({ ...errObj, jobRoleErr: false, jobRoleMsg: "" });
                  setErrObj({
                    ...errObj,
                    newJobRoleErr: false,
                    newJobRoleMsg: "",
                  });
                }
              }}
            >
              My job role is not listed
            </Typography>
            {viewNewJob && (
              <TextField
                placeholder="Enter your job role"
                value={newJob}
                error={errObj.newJobRoleErr}
                onChange={(v) => {
                  setNewJob(v.target.value);
                  setErrObj({
                    ...errObj,
                    newJobRoleErr: false,
                    newJobRoleMsg: "",
                  });
                }}
                type="text"
                helperText={errObj.newJobRoleErr ? errObj.newJobRoleMsg : null}
                style={{ maxWidth: sm ? "unset" : 300, marginTop: 10 }}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              style={{ width: sm ? "unset" : 300, marginTop: 20 }}
              onClick={() => {
                checkValidation();
              }}
            >
              Next step
            </Button>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={6}
            alignItems="center"
            justifyContent="center"
          >
            {sm ? null : (
              <img
                alt="Error"
                src={Images?.heroimg}
                style={{ height: "100%", width: "100%" }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSecond() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: sm ? 5 : 1 }}>
        <Grid
          container
          style={{
            padding: 20,
            backgroundColor: "#f5f9ff",
            borderRadius: 8,
            paddingBottom: 30,
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              How our service works
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 30 }}
          >
            <Grid item xs={12} sm={3.6}>
              <div className="secondGrid">
                <Typography
                  style={{
                    marginBottom: 6,
                  }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Create a profile for free
                </Typography>
                <Typography
                  className="mediumfont lightsilvercolor"
                  lineHeight={2}
                  paragraph
                >
                  Tell us what you are looking for
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={0.5} justifyContent="center">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {sm ? (
                  <ArrowDownward
                    style={{ color: "#D6AC85", marginTop: 4, marginBottom: 4 }}
                  />
                ) : (
                  <ArrowForward style={{ color: "#D6AC85" }} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3.6}>
              <div className="secondGrid">
                <Typography
                  style={{ marginBottom: 6 }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Get matched
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  Employers get in touch or show your interest
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={0.5} justifyContent="center">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {sm ? (
                  <ArrowDownward
                    style={{ color: "#D6AC85", marginTop: 4, marginBottom: 4 }}
                  />
                ) : (
                  <ArrowForward style={{ color: "#D6AC85" }} />
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={3.6}>
              <div className="secondGrid">
                <Typography
                  style={{ marginBottom: 6 }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Connect directly
                </Typography>
                <Typography
                  className="mediumfont lightsilvercolor"
                  lineHeight={2}
                  paragraph
                >
                  Get hired
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderThird() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              What candidates says about us
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={7.5}>
              <Slider ref={firstSliderImgRef} {...firstSetting}>
                {candidateReview.map((ii, index) => {
                  return (
                    <>
                      <div key={index} style={{ display: "flex" }}>
                        <img
                          alt="Error"
                          src={ii?.photo}
                          className="sliderimg"
                        />
                      </div>
                    </>
                  );
                })}
              </Slider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4.5}
              style={{ position: "relative", marginTop: sm ? 20 : 0 }}
            >
              <div className="maindiv">
                {sm ? null : <div className="divdesign" />}
                <div className="remaindiv">
                  <div className="arrowmaindiv">
                    <div
                      className="arrowdiv"
                      onClick={() => {
                        firstSliderRef.current.slickPrev();
                        firstSliderImgRef.current.slickPrev();
                      }}
                    >
                      <KeyboardArrowLeft
                        style={{ color: "#fff", fontSize: sm ? 30 : 40 }}
                      />
                      <div
                        className="arrowdiv arrowdiv2"
                        onClick={() => {
                          firstSliderRef.current.slickNext();
                          firstSliderImgRef.current.slickNext();
                        }}
                      >
                        <KeyboardArrowRight
                          style={{ color: "#fff", fontSize: sm ? 30 : 40 }}
                        />
                      </div>
                    </div>
                  </div>
                  <Slider ref={firstSliderRef} {...firstSetting}>
                    {candidateReview.map((ii, index) => {
                      return (
                        <>
                          <div key={index} className="messsagefromclien">
                            <div className="messageheder">
                              <Typography className="primarycolor mediumlargefont">
                                {ii.name}
                              </Typography>
                              <div className="underline" />
                              <Rating
                                name="half-rating"
                                defaultValue={ii?.rating}
                                precision={0.5}
                                readOnly
                              />
                            </div>
                            <div
                              className="sliderscroll"
                              style={{
                                margin: 20,
                                maxHeight: md ? 240 : 320,
                              }}
                            >
                              <Typography className="regulerfont">
                                {ii.description}
                              </Typography>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderFourth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: sm ? 10 : 20 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ padding: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              3 steps to start your work adventure
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              direction="column"
              justifyContent="center"
            >
              <Typography
                className="primarycolor fontsize2"
                style={{ marginBottom: 6 }}
              >
                1. Create profile
              </Typography>
              <Typography
                className="lightsilvercolor"
                style={{ lineHeight: 2, fontSize: 18 }}
              >
                Why not showcase your CV and your profile to potential employers
                with all your qualities. You can showcase your skills,
                experience, knowledge, and future actions to allow employers to
                make a better judgment based on seeing you as you are, not just
                a document. You can answer questions about how you would perform
                in a similar job setting to set you apart from everyone else.
                You can even share a profile picture and upload a short video to
                convince employers that you are the best fit for the role.
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt="Error"
                src={Images?.step1}
                style={{ height: "100%", width: "100%" }}
              />
            </Grid>
            <Grid container xs={12} direction="row-reverse">
              <Grid
                container
                xs={12}
                sm={6}
                md={6}
                direction="column"
                justifyContent="center"
              >
                <Typography
                  className="primarycolor fontsize2"
                  style={{ marginBottom: 6 }}
                >
                  2. Get matched
                </Typography>
                <Typography
                  className="lightsilvercolor"
                  style={{ lineHeight: 2, fontSize: 18 }}
                >
                  Once your profile is complete, you will be matched to jobs
                  based on what you are looking for, and we will put your
                  profile forward instantly to relevant employers. All you have
                  to do is show interest, and employers will connect with you
                  directly. There are no fees, middle person, or company
                  involved, just a direct route to get your dream job.
                </Typography>
              </Grid>
              <Grid
                container
                xs={12}
                sm={6}
                md={6}
                alignItems="center"
                justifyContent="center"
              >
                <img
                  alt="Error"
                  src={Images?.step2}
                  style={{ height: "100%", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              direction="column"
              justifyContent="center"
            >
              <Typography
                className="primarycolor fontsize2"
                style={{ marginBottom: 6 }}
              >
                3. Get hired
              </Typography>
              <Typography
                className="lightsilvercolor"
                style={{ lineHeight: 2, fontSize: 18 }}
              >
                Employers can message you and make job offers directly on our
                platform. You can browse the company’s profile instantly for
                free, including a video from the employer, information about the
                company, and the job role. All you need to do is accept the job
                offer – job done! Recruitment is now easier, faster, and simpler
                on the Direct Expertise website. Why not create your profile
                free and unlock the door to a bright and exciting future?
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt="Error"
                src={Images?.step3}
                style={{ height: "100%", width: "100%" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderFifth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10, marginBottom: 10 }}>
        <Grid
          container
          style={{ padding: 20, backgroundColor: "#f5f9ff", borderRadius: 8 }}
        >
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              What candidates says about us
            </Typography>
          </Grid>
          <Grid container xs={12} alignItems="center" justifyContent="center">
            <Grid item xs={1}>
              <Button
                variant="outlined"
                className="buttun"
                onClick={() => {
                  sliderRef.current.slickPrev();
                }}
              >
                <KeyboardArrowLeft style={{ fontSize: 20 }} />
              </Button>
            </Grid>
            <Grid item xs={10}>
              <Slider ref={sliderRef} {...setting} className="carousal">
                {candidateReview.map((ii, index) => {
                  return (
                    <Grid item container xs={12} key={index}>
                      <div className="slider12">
                        <div className="msg-icn">
                          <Typography
                            className="lightsilvercolor regulersmallfont"
                            style={{ marginBottom: 16 }}
                          >
                            {ii?.description}
                          </Typography>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography className="primarycolor smallsemiboldfont">
                              {ii?.name}
                            </Typography>

                            <Rating
                              name="half-rating"
                              defaultValue={ii?.rating}
                              precision={0.5}
                              readOnly
                              style={{ marginLeft: 6, fontSize: 20 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="profilecmt">
                        <Avatar
                          className="avatarUI"
                          alt="error"
                          src={ii.photo}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Slider>
            </Grid>
            <Grid item xs={1} justifyContent="flex-end">
              <div
                style={{
                  display: sm ? "unset" : "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  className="buttun"
                  onClick={() => {
                    sliderRef.current.slickNext();
                  }}
                >
                  <KeyboardArrowRight style={{ fontSize: 20 }} />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSixth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10, marginBottom: 5 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: 26 }}
          >
            <Typography className="fontsize" style={{ textAlign: "center" }}>
              Get our PWA app on Android and iPhone and share on
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="iconstyle"
                onClick={() => {
                  navigator.clipboard.writeText(Setting?.mainURL);
                  toast.success("linked successful copied to clipboard");
                }}
              >
                <ContentCopy style={{ color: "#fff", fontSize: 18 }} />
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FacebookShareButton url={Setting?.mainURL}>
                <div className="iconstyle">
                  <img
                    alt="Error"
                    src={Images?.faceBook}
                    style={{ height: "60%", width: "60%" }}
                  />
                </div>
              </FacebookShareButton>
            </Grid>
            {/* <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.insta}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid> */}
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <LinkedinShareButton url={Setting?.mainURL}>
                <div className="iconstyle">
                  <img
                    alt="Error"
                    src={Images?.linkedin}
                    style={{ height: "60%", width: "60%" }}
                  />
                </div>
              </LinkedinShareButton>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TwitterShareButton url={Setting?.mainURL}>
                <div className="iconstyle">
                  <img
                    alt="Error"
                    src={Images?.twitter}
                    style={{ height: "60%", width: "60%" }}
                  />
                </div>
              </TwitterShareButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <div>
      {renderFirst()}
      {renderSecond()}
      {/* {isCandidateEmpty ? null : renderThird()} */}
      {renderFourth()}
      {isCandidateEmpty ? null : renderFifth()}
      {renderSixth()}
    </div>
  );
}
