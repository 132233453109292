import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import useOnlineStatus from "@rehooks/online-status";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import authActions from "./Redux/reducers/auth/actions";
import "slick-carousel/slick/slick.css";
import { initializeApp } from "firebase/app";
import _ from "lodash";
import "./App.css";
import "./globleStyle.scss";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

// Common Screen
import Offline from "./Screens/Offline/index";
import Verification from "./Screens/Verification";
import ResetPass from "./Screens/ResetPass";

// CMS Screen
import About_Us from "./Screens/CMS/About_Us";
import Contact_Us from "./Screens/CMS/Contact_Us";
import Privacy_Policy from "./Screens/CMS/Privacy_Policy";
import Terms_Condition from "./Screens/CMS/Terms_Condition";

// Candidate Routes
import Candidate_Home from "./Screens/Candidate/Home/index";
import Candidate_Jobs from "./Screens/Candidate/Jobs/index";
import Candidate_SignUp from "./Screens/Candidate/SignUp/index";
import Candidate_Profile from "./Screens/Candidate/Profile/index";
import Candidate_Account from "./Screens/Candidate/Account/index";
import Candidate_Preference from "./Screens/Candidate/Preference/index";
import Candidate_Quiz from "./Screens/Candidate/Quiz/index";

// Employer Routes
import Employer_Home from "./Screens/Employer/Home/index";
import Employer_Jobs from "./Screens/Employer/Jobs/index";
import Employer_Account from "./Screens/Employer/Account/index";
import Employer_Company from "./Screens/Employer/Company/index";
import MatchingJob from "./Screens/Employer/MatchingJob";
import PostJob from "./Screens/Employer/PostJob";
import ViewCandidate from "./Screens/Employer/ViewCandidate";
import CompanyProfile from "./Screens/Common/CompanyProfile";
import CompanyQuestion from "./Screens/Common/QuestionList";
import CandidateResult from "./Screens/Common/CandidateResult";

import NotificationPopup from "./Components/NotificationPopUp/index";
import Unsubscribe from "./Screens/CMS/Unsubscribe";
import ViewAllJobs from "./Screens/Employer/ViewAllJobs";

const firebaseConfig = {
  apiKey: "AIzaSyDqH08yiOS38sgbVMGCVf-nyQP9-D88hKo",
  authDomain: "direct-expertise-56038.firebaseapp.com",
  projectId: "direct-expertise-56038",
  storageBucket: "direct-expertise-56038.appspot.com",
  messagingSenderId: "232985582305",
  appId: "1:232985582305:web:c6ac37e2ca547365e635a0",
  measurementId: "G-WGTDHDNNZ2",
};

function App() {
  const { userdata, token, isOnline } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { setOnlineStatus } = authActions;
  const onlineStatus = useOnlineStatus();

  const [isLogin, setIsLogin] = useState(false);
  const [userType, setUserType] = useState("user");

  const isCandidate = _.toLower(userType) === "user";

  initializeApp(firebaseConfig);

  useEffect(() => {
    if (_.isObject(userdata) && !_.isEmpty(userdata)) {
      setIsLogin(true);
      setUserType(userdata?.role);
    } else {
      setIsLogin(false);
    }
  }, [userdata]);

  // onLine status set
  useEffect(() => {
    dispatch(setOnlineStatus(onlineStatus));
  }, [onlineStatus]);

  return isOnline ? (
    <Router>
      <Header isCandidate={isCandidate} />
      <div className="MT60">
        <Routes>
          {isLogin && isCandidate ? (
            <Route
              exact
              path="/candidate/account"
              element={<Candidate_Account />}
            />
          ) : isLogin && !isCandidate ? (
            <Route
              exact
              path="/employer/account"
              element={<Employer_Account />}
            />
          ) : (
            <Route exact path={"/"} element={<Candidate_Home />} />
          )}
          <Route path="/employer/home" element={<Employer_Home />} />
          <Route path="/candidate/signup" element={<Candidate_SignUp />} />
          <Route path="/cms/about-us" element={<About_Us />} />
          <Route path="/cms/contact-us" element={<Contact_Us />} />
          <Route path="/cms/privacy-policy" element={<Privacy_Policy />} />
          <Route
            path="/cms/terms-and-condition"
            element={<Terms_Condition />}
          />

          {token !== "" ? (
            <>
              <Route path="/employer/jobs" element={<Employer_Jobs />} />
              <Route path="/employer/account" element={<Employer_Account />} />
              <Route path="/employer/company" element={<Employer_Company />} />
              <Route path="/candidate/jobs" element={<Candidate_Jobs />} />
              <Route
                path="/candidate/profile"
                element={<Candidate_Profile />}
              />
              <Route
                path="/candidate/account"
                element={<Candidate_Account />}
              />
              <Route
                path="/candidate/preferences"
                element={<Candidate_Preference />}
              />
              <Route path="/candidate/quiz" element={<Candidate_Quiz />} />
              <Route path="/company/profile" element={<CompanyProfile />} />
              <Route path="/company/question" element={<CompanyQuestion />} />
              <Route
                path="/employer/jobs/matching-job"
                element={<MatchingJob />}
              />
              <Route
                path="/employer/jobs/view-jobs"
                element={<ViewAllJobs />}
              />
              <Route path="/employer/jobs/post-job" element={<PostJob />} />
              <Route
                path="/employer/jobs/candidate-result"
                element={<CandidateResult />}
              />
              <Route
                path="/employer/jobs/candidates"
                element={<ViewCandidate />}
              />
              <Route path="/unsubscribe_me" element={<Unsubscribe />} />
            </>
          ) : null}
          <Route path="/reset-password" element={<ResetPass />} />
          <Route path="/verification" element={<Verification />} />
          <Route
            path="*"
            element={
              <Navigate
                to={
                  isLogin
                    ? isCandidate
                      ? "/candidate/profile"
                      : "/employer/company"
                    : "/"
                }
              />
            }
          />
        </Routes>
      </div>
      <Footer />
      <NotificationPopup />
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route exact path={"/"} element={<Offline />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </Router>
  );
}

export default App;
