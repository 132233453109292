const Images = {
  header_logo: "/Assets/Images/logo.png",
  skills: "/Assets/Images/skills.png",
  availability: "/Assets/Images/availability.png",
  job: "/Assets/Images/job.png",
  education: "/Assets/Images/education.png",
  experience: "/Assets/Images/experience.png",
  language: "/Assets/Images/language.png",
  close: "/Assets/Images/close.png",
  placeholder: "/Assets/Images/placeholder.png",
  email: "/Assets/Images/email.png",
  location: "/Assets/Images/location.png",
  phone: "/Assets/Images/phone.png",
  edit: "/Assets/Images/edit.png",
  camera: "/Assets/Images/camera.png",
  heroimg: "/Assets/Images/heroimg.png",
  step1: "/Assets/Images/1.png",
  step2: "/Assets/Images/2.png",
  step3: "/Assets/Images/3.png",
  install: "/Assets/Images/install.png",
  faceBook: "/Assets/Images/fb.png",
  google: "/Assets/Images/google.png",
  insta: "/Assets/Images/insta.png",
  linkedin: "/Assets/Images/linkedin.png",
  twitter: "/Assets/Images/twitter.png",
  demo: "/Assets/Images/demo.png",
  angle: "/Assets/Images/angle.png",
  delete: "/Assets/Images/delete.png",
  odelete: "/Assets/Images/odelete.png",
  bio: "/Assets/Images/bio.png",
  picker: "/Assets/Images/picker.png",
  welcome: "/Assets/Images/welcome.png",
  heroimg2: "/Assets/Images/heroimg2.png",
  estep1: "/Assets/Images/e1.png",
  estep2: "/Assets/Images/e2.png",
  estep3: "/Assets/Images/e3.png",
  icon1: "/Assets/Images/42.png",
  icon2: "/Assets/Images/43.png",
  icon3: "/Assets/Images/44.png",
  icon4: "/Assets/Images/45.png",
  icon5: "/Assets/Images/46.png",
  icon6: "/Assets/Images/47.png",
  timer: "/Assets/Images/timer.png",
  orangeP: "/Assets/Images/orangepound.png",
  orangeS: "/Assets/Images/orangeSkills.png",
  linkedinTheme: "/Assets/Images/linkedina.png",
  google_logo: "/Assets/Images/googleLogo.png",
  FB_logo: "/Assets/Images/facebookLogo.png",
  linkedin_logo: "/Assets/Images/linkdinLogo.png",
  Fb_blue_logo: "/Assets/Images/facebook.png",
  contact: "/Assets/Images/contact.jpg",
  videoThumbnail: "/Assets/Images/videothumbnail.jpeg",
};

export default Images;
