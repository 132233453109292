/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { cloneDeep, flattenDeep, isArray, isEmpty, isNull } from "lodash";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MJob from "../../../Components/Jobs/MJob";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData, getApiData } from "../../../Utils/APIHelper";
import { milesList } from "../../../Config/Static_Data";
import useStyles from "./styles";
import CDropDown from "../../../Components/CDropDown";
import InfoIcon from "@mui/icons-material/Info";

export default function MatchingJob() {
  const classes = useStyles();
  const location = useLocation();
  const { token, dropdowndata } = useSelector((state) => state.auth);
  const jobId = !isEmpty(location?.search)
    ? location?.search?.substring(8)
    : location?.state?.jobId || null;
  const mainData = location?.state?.data || null;

  const [allSkill, setAllSkill] = useState([]);
  const [allEducation, setAllEducation] = useState([]);
  const [allExperience, setAllExperience] = useState([]);
  const [selectedMiles, setSelectedMiles] = useState("");
  const [profileDetail, setProfileDetail] = useState({});

  const [jobs, setJobs] = useState({
    data: [],
    pageData: {},
  });
  const [pageLoad, setPageLoad] = useState(true);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_MATCHING_JOB;
    window.scrollTo(0, 0);
    getAllSKills();
    getCompanyDetails();
  }, []);

  useEffect(() => {
    if (jobId) {
      setPageLoad(true);
      getMatchingJobList();
    } else {
      setPageLoad(false);
    }
  }, [jobId, allSkill, allEducation, allExperience, selectedMiles]);

  // this function for get company details
  async function getCompanyDetails() {
    try {
      const response = await getApiData(
        Setting.endpoints.employerCompanyDetails,
        "GET",
        {},
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status) {
        if (!isEmpty(response.data)) {
          setProfileDetail(response?.data || {});
        }
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  }

  const setInitialFilter = (skills) => {
    const dummy_arr = cloneDeep(dropdowndata?.education_level);
    const expYear = mainData?.required_experience.slice(0, 2);

    let ex_Arr = [
      {
        label: "No experience required",
        value: "0",
        isCheck: false,
        isDisable: false,
      },
    ];

    isArray(skills) &&
      skills.map((item) => {
        isArray(mainData?.skills) &&
          mainData?.skills?.map((it) => {
            if (item?.value === Number(it)) {
              item.isCheck = true;
              item.isDisable = true;
            }
          });
      });

    dummy_arr?.map((item) => {
      if (item?.value === mainData?.education_level) {
        item.isCheck = true;
        item.isDisable = true;
      } else {
        item.isCheck = false;
        item.isDisable = false;
      }
    });

    for (var i = 1; i < expYear; i++) {
      const mainObj = {
        label: i,
        value: i,
        isCheck: false,
        isDisable: false,
      };
      ex_Arr.push(mainObj);
    }

    setAllSkill(skills);
    setAllEducation(mainData?.education_level === 270 ? [] : dummy_arr);
    setAllExperience(mainData?.required_experience === "0" ? [] : ex_Arr);
  };

  function allCheckboxFun(type, val) {
    const dummy_arr = cloneDeep(allSkill);
    const dummy_arr1 = cloneDeep(allEducation);
    const dummy_arr2 = cloneDeep(allExperience);

    if (type === "skill") {
      dummy_arr?.map((item) => {
        if (item?.value === val) {
          item.isCheck = !item.isCheck;
        }
      });
    }

    if (type === "education") {
      dummy_arr1?.map((item) => {
        if (val === 270) {
          if (item?.value === val) {
            item.isCheck = !item.isCheck;
            item.isDisable = false;
          } else {
            item.isCheck = false;
            item.isDisable = false;
          }
        } else {
          if (item?.value === val) {
            item.isCheck = !item.isCheck;
          } else if (item?.value === 270) {
            item.isCheck = false;
          }
        }
      });
    }

    if (type === "experience") {
      dummy_arr2?.map((item) => {
        if (val === "0") {
          if (item?.value === val) {
            item.isCheck = !item.isCheck;
            item.isDisable = false;
          } else {
            item.isCheck = false;
            item.isDisable = false;
          }
        } else {
          if (item?.value === val) {
            item.isCheck = !item.isCheck;
          } else if (item?.value === "0") {
            item.isCheck = false;
          }
        }
      });
    }

    type === "skill" && setAllSkill(dummy_arr);
    type === "education" && setAllEducation(dummy_arr1);
    type === "experience" && setAllExperience(dummy_arr2);
  }

  // this function for get all skills list
  async function getAllSKills() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.employerAllSkill}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isArray(response?.data?.skills) &&
          !isEmpty(response?.data?.skills)
        ) {
          setInitialFilter(response?.data?.skills);
        }
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  // this function for get matching job list
  async function getMatchingJobList(key = "") {
    let page = Number(jobs?.pageData?.currentPage) || 1;
    if (key === "load") {
      page = page + 1;
    }

    let skillNo = 0;
    let educationNo = 0;
    let experienceNo = 0;

    const filterData = {
      job_post_id: jobId,
      location: selectedMiles,
    };

    if (isArray(allSkill) && !isEmpty(allSkill)) {
      allSkill.map((item) => {
        if (item?.isCheck) {
          const isThere = mainData?.skills?.includes(item?.value?.toString());
          if (!isThere) {
            filterData[`skill[${skillNo}]`] = item?.value;
            skillNo = skillNo + 1;
          }
        }
      });
    }

    if (isArray(allEducation) && !isEmpty(allEducation)) {
      allEducation.map((item) => {
        if (item?.isCheck) {
          if (item?.value === 270) {
            filterData[`no_education`] = 1;
          } else {
            filterData[`education[${educationNo}]`] = item?.value;
            educationNo = educationNo + 1;
          }
        }
      });
    }

    if (isArray(allExperience) && !isEmpty(allExperience)) {
      allExperience.map((item) => {
        if (item?.isCheck) {
          if (item?.value === "0") {
            filterData[`no_experience`] = 1;
          } else {
            filterData[`experience[${experienceNo}]`] = item?.value;
            experienceNo = experienceNo + 1;
          }
        }
      });
    }

    try {
      const response = await getAPIProgressData(
        `${Setting.endpoints.jobGetSortedCandidate}?page=${page}`,
        "POST",
        filterData,
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          const newData =
            key === "load"
              ? flattenDeep([...jobs?.data, response?.data?.rows])
              : response?.data?.rows;
          setJobs({
            data: newData || [],
            pageData: response?.data?.pagination,
          });
        }
      }
      setPageLoad(false);
      setLoadMoreBtn(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
      setLoadMoreBtn(false);
    }
  }

  const loadMoreData = jobs?.pageData?.isMore || false;

  const renderSkillFilter = useMemo(() => {
    return (
      <Grid
        item
        xs={12}
        className={[
          classes.shadowViewStyle,
          classes.paddingStyle,
          classes.marginDiv,
        ]}
      >
        <Typography className={classes.sectionTitle} style={{ marginLeft: 9 }}>
          Skills
        </Typography>
        {allSkill?.map((item) => {
          return (
            <div style={{ display: "flex" }}>
              <Checkbox
                checked={item?.isCheck}
                onClick={() => {
                  allCheckboxFun("skill", item?.value);
                }}
                disabled={item?.isDisable}
              />
              <Typography className={classes.sectionDesc}>
                {item?.label}
              </Typography>
            </div>
          );
        })}
      </Grid>
    );
  }, [allSkill]);

  const renderEducationFilter = useMemo(() => {
    return (
      isArray(allEducation) &&
      !isEmpty(allEducation) && (
        <Grid
          item
          xs={12}
          className={[
            classes.shadowViewStyle,
            classes.paddingStyle,
            classes.marginDiv,
          ]}
        >
          <Typography
            className={classes.sectionTitle}
            style={{ marginLeft: 9 }}
          >
            Education
          </Typography>
          {allEducation?.map((item) => {
            return (
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={item?.isCheck}
                  onClick={() => {
                    allCheckboxFun("education", item?.value);
                  }}
                  disabled={item?.isDisable}
                />
                <Typography className={classes.sectionDesc}>
                  {item?.label}
                </Typography>
              </div>
            );
          })}
        </Grid>
      )
    );
  }, [allEducation]);

  const renderExperienceFilter = useMemo(() => {
    return (
      isArray(allExperience) &&
      !isEmpty(allExperience) && (
        <Grid
          item
          xs={12}
          className={[
            classes.shadowViewStyle,
            classes.paddingStyle,
            classes.marginDiv,
          ]}
        >
          <Typography
            className={classes.sectionTitle}
            style={{ marginLeft: 9 }}
          >
            Experience
          </Typography>
          {allExperience?.map((item, index) => {
            return (
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={item?.isCheck}
                  onClick={() => {
                    allCheckboxFun("experience", item?.value);
                  }}
                  disabled={item?.isDisable}
                />
                <Typography className={classes.sectionDesc}>
                  {item?.label}
                </Typography>
              </div>
            );
          })}
        </Grid>
      )
    );
  }, [allExperience]);

  const renderLocationFilter = useMemo(() => {
    return (
      <Grid
        item
        xs={12}
        className={[
          classes.shadowViewStyle,
          classes.paddingStyle,
          classes.marginDiv,
        ]}
      >
        <Typography className={classes.sectionTitle} style={{ marginLeft: 9 }}>
          {`Postcode:- ${
            mainData?.location ? mainData?.location : profileDetail?.location
          }`}
        </Typography>
        <CDropDown
          data={milesList}
          value={selectedMiles}
          onChange={(v) => {
            setSelectedMiles(v.target.value);
          }}
          placeholder="Select miles"
          style={{ width: "100%" }}
        />
      </Grid>
    );
  }, [selectedMiles, profileDetail]);

  return (
    <Grid container className={classes.container}>
      <Typography className={classes.sectionTitle} style={{ marginBottom: 20 }}>
        View Matching
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={2.9}>
          <Grid
            container
            flexDirection={"row"}
            style={{ alignItems: "center", marginBottom: 10 }}
          >
            <Typography className={classes.sectionTitle}>Filters</Typography>
            <Tooltip title="Select additional filters to expand your search">
              <InfoIcon
                fontSize="10"
                style={{ color: "#5A5A5A", marginLeft: 4 }}
              />
            </Tooltip>
          </Grid>
          {renderSkillFilter}
          {renderEducationFilter}
          {renderExperienceFilter}
          {(!isNull(mainData?.location) || !isNull(profileDetail?.location)) &&
            profileDetail?.location !== "null" &&
            renderLocationFilter}
        </Grid>
        <Grid item xs={12} sm={8.9}>
          {pageLoad ? (
            <div className={classes.loaderView}>
              <CircularProgress size={50} />
            </div>
          ) : isArray(jobs?.data) && jobs?.data.length > 0 ? (
            jobs?.data.map((item) => {
              return (
                <MJob
                  key={`job_${item?.job_post_id}`}
                  data={item}
                  updateList={() => {
                    setPageLoad(true);
                    getMatchingJobList();
                  }}
                />
              );
            })
          ) : (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No Candidates available
              </Typography>
            </div>
          )}
          {loadMoreData && (
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={3}>
                <Button
                  variant={loadMoreBtn ? "outlined" : "contained"}
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setLoadMoreBtn(true);
                    getMatchingJobList("load");
                  }}
                  disabled={loadMoreBtn}
                >
                  {loadMoreBtn ? <CircularProgress size={28} /> : "Load More"}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
